import _ from 'lodash-es';
import React, { useEffect } from "react";
import './Feature.less';
import { Layout, Typography, Row, Col } from 'antd';
import teamImg from '../../resources/images/howto_team_mgmt.gif';
import boardImg from '../../resources/images/howto_create_board.gif';
import itemImg from '../../resources/images/howto_create_item.gif';
import itemCommentImg from '../../resources/images/howto_comment.gif';
import createTodoImg from '../../resources/images/howto_create_todo.gif';
import trackTodoImg from '../../resources/images/howto_track_todo.gif';

const { Paragraph } = Typography;

const { Content } = Layout;

interface HowToContent {
  title: string;
  img: string;
  imgClass?: string;
}
const HowToPage: React.FC<{}> = () => {
  useEffect(() => {
    const currentTitle = document.title;
    document.title = 'TeamKit - How it works?';
    return () => {
      document.title = currentTitle;
    }
  }, []);

  const contents: Array<HowToContent> = [
    { title: '1. Setup your team', img: teamImg },
    { title: '2. Create retrospective board', img: boardImg },
    { title: '3. Add the items for discussion', img: itemImg },
    { title: '4. Vote or comment on the items', img: itemCommentImg, imgClass: "img-small" },
    { title: '5. Create tasks linked to the item', img: createTodoImg, imgClass: "img-small" },
    { title: '6. Track the tasks', img: trackTodoImg },
  ];

  return (
    <Content className="site-content">
      <div className="static-page-container" style={{ background: 'white' }}>
        <div className="site-content-inner">
          <Paragraph><h3>This it our suggested main flow</h3></Paragraph>
        </div>
        {_.map(contents, ((content: HowToContent) => (
          <div className="site-content-inner">
            <Row>
              <Col lg={6} md={24}>
                <h4>{content.title}</h4>
                <br/>
              </Col>
              <Col lg={18} md={24} className="howto-img-container">
                <img src={content.img} alt="feature-img" className={content.imgClass} />
              </Col>
            </Row>
          </div>
        )))}
      </div>
    </Content>
  )
}

export default HowToPage;
