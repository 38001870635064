// common images
import landingPageRetrospective from '../../resources/images/landing-page-retrospective.png';
import landingPageGoal from '../../resources/images/landing-page-goal.png';
import landingPageCheckin from '../../resources/images/landing-page-checkin.png';
// retrospective images
import createBoardImg from '../../resources/images/tutorial-retro-1-create-board.png';
import itemImg from '../../resources/images/tutorial-retro-2-add-item.png';
import itemCommentImg from '../../resources/images/tutorial-retro-3-comment-like.png';
import createTodoImg from '../../resources/images/tutorial-retro-4-create-tasks.png';
import trackTodoImg from '../../resources/images/tutorial-task-1-track-with-status.png';
import pollImg from '../../resources/images/tutorial-retro-5-poll.jpg';
// goals images
import createGoalGroupImg from '../../resources/images/tutorial-goal-1-create-goal-group.png';
import createGoalImg from '../../resources/images/tutorial-goal-2-create-goal.png';
import recordProgresstImg from '../../resources/images/tutorial-goal-3-record-target-process-update.png';
import visualiseAndCommunicateImg from '../../resources/images/tutorial-goal-4-visualise-and-communicate.png';
// daily checkin
import createCheckin from '../../resources/images/tutorial-checkin-1-log-your-daily-checkin.png';
import visualiseCheckin from '../../resources/images/tutorial-checkin-2-visualise.png';
import commentCheckin from '../../resources/images/tutorial-checkin-3-comments.png';
// scrum  poker images
import createSessionImg from '../../resources/images/tutorial-poker-1-create.png';
import voteImg from '../../resources/images/tutorial-poker-2-vote.png';
import onlineStatusImg from '../../resources/images/tutorial-poker-3-online-status.png';
import resultsImg from '../../resources/images/tutorial-poker-4-results.png';
import landingPageScrumPoker from '../../resources/images/landing-page-scrum-poker.png';
import { CalculatorIcon, LockClosedIcon, ChatBubbleLeftRightIcon, ClipboardDocumentCheckIcon, ChartBarIcon, CheckCircleIcon, FaceSmileIcon, ArrowTrendingUpIcon, RocketLaunchIcon,  } from '@heroicons/react/20/solid';

export interface FeatureDefinition {
  id: string;
  prefix: string;
  title: string;
  shortDescription?: string;
  longDescription?: string;
  image: string;
  features: Array<Feature>;
  howtos: Array<HowToContent>;
}

export interface Feature {
  name: string;
  description: string;
  icon: any;
}

export interface HowToContent {
  title: string;
  description?: string;
  img: string;
}

export const featureRetrospective = 
{
  id: 'retrospective',
  prefix: "Work smarter",
  title: "A better retrospective tool",
  shortDescription: null,
  longDescription: "Retrospective is not just about sticky notes and drawing tools; it's about driving real change. Track action items, gather insights with polling, and foster collaboration with like and comment functionalities. Make retrospectives more impactful and results-driven today!",
  image: landingPageRetrospective,
  features: [
    {
      name: "Real time like and comment.",
      description:
        "Easily provide feedback, express support, and drive meaningful discussions with like and comment features.",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Action item tracking.",
      description:
        "Never miss an action item again with our robust action tracking feature.",
      icon: ClipboardDocumentCheckIcon,
    },
    {
      name: "Live poll.",
      description:
        "Gain real-time insights into your team's thoughts and feelings, designed to foster meaningful discussions and drive actionable outcomes.",
      icon: CheckCircleIcon,
    },
    {
      name: "Smart report with Generative AI.",
      description:
        "Discover the pulse of your retrospectives with our Generative AI Report feature, designed to pinpoint hot topics and critical insights within your team's feedback.",
      icon: ChartBarIcon,
    },
  ],
  howtos: [
    { 
      title: "Predefined retrospective templates",
      img: createBoardImg,
      description: "Generate retrospective sessions using a variety of predefined templates. Select your desired board settings, including public or private access, along with the option for anonymous or transparent posting."
    },
    {
      title: "Real time collaboration",
      img: itemCommentImg,
      description: "Determine important issues by allowing users to vote or provide comments on matters they perceive as relevant.",
    },
    {
      title: "Action item creation",
      img: createTodoImg,
      description: "Ensure you never overlook any action item by creating tasks directly within your retrospective boards.",
    },
    {
      title: "Task tracking",
      img: trackTodoImg,
      description: "Assign and track the task till the end with our simple task management feature", 
    },
    {
      title: "Real time poll", img: pollImg, description: "Collect your team's insights, conduct ice-breaking Q&A sessions, and more, all are made possible with the built-in poll feature."},
  ],
}

export const featureGoalTracking = {
  id: 'goal-tracking',
  prefix: "Work with more focus",
  title: "Goal tracking tool",
  shortDescription:
    "Track your way to success with our stunning goal tracking feature",
  longDescription: 
    "Unlock your path to success with TeamKit's Goal Tracking feature. Never miss a goal again as you effortlessly set, break down into milestones, and regularly track your progress, all in one convenient platform. Stay on course to achieve your missions and ensure every step takes you closer to success.",
  image: landingPageGoal,
  features: [
    {
      name: "Milestone breakdown.",
      description:
        "Effortlessly set, monitor, and celebrate your key milestones, providing a clear roadmap to your goals and helping you stay motivated every step of the way.",
      icon: ArrowTrendingUpIcon,
    },
    {
      name: "Comment & Collaboration.",
      description:
        "Share insights, offer support, and foster meaningful discussions as you interact with your goals, keeping you motivated and accountable as you work toward your ambitions.",
      icon: ChatBubbleLeftRightIcon,
    },
    {
      name: "Visibility control.",
      description:
        "Decide if your goal is private or public. Share your private goals with relevant team members. You have it all covered.",
      icon: LockClosedIcon,
    },
  ],
  howtos: [
    { title: 'Goal group', description: 'Goal group is a way to groop related goals together, normally goals from the same department in the same quarter', img: createGoalGroupImg },
    { title: 'Goals with measurable milestones', description: 'Goal is more about objective. Milestone is a breakdown of a goal into multiple measurable components', img: createGoalImg },
    { title: 'Record target progress update regularly', description: "Stay on the path to success by regularly updating on your milestone progress.", img: recordProgresstImg},
    { title: 'Visualise and communicate to hit the goals and targets', description: "Quickly visualise your entire progress with our simple and intuitive UI", img: visualiseAndCommunicateImg },
  ]
};

export const featureDailyCheckin = {
  id: 'daily-checkin',
  prefix: "Move faster as a team",
  title: "Daily check-in",
  shortDescription:
    "Streamline updates, identify blockers and analyse your team mood in minutes.",
  longDescription:
    "Simplify daily check-in for global teams with our intuitive tool. Identify and swiftly resolve blockers on your journey to success.",
  image: landingPageCheckin,
  features: [
    {
      name: "Mood check-in.",
      description:
        "Gain real-time insights into team morale and well-being, fostering a positive and connected work environment that drives productivity and engagement.",
      icon: FaceSmileIcon,
    },
    {
      name: "Blocker tracking & assignment.",
      description:
        "Easily identify, assign, and resolve obstacles that hinder progress, ensuring your team's standup sessions become a catalyst for action and unparalleled productivity.",
      icon: RocketLaunchIcon,
    },
    {
      name: "Comment & Collaboration.",
      description:
        "Share insights, offer support, and foster meaningful discussions. Daily check-in is not just about status report, it's a platform for your team to collaborate and move faster together.",
      icon: ChatBubbleLeftRightIcon,
    },
  ],
  howtos: [
    { title: 'Log your daily check-in', description: 'Identify blockers that prevent you from delivering your work, make sure you assign it to an individual who can potentially clear out those obstacles for you.', img: createCheckin },
    { title: 'Visualise your team progress and overal daily analysis', description: 'Proactively identify and mitigate redundant and overlapping tasks to optimize productivity. Foster a supportive team environment by providing assistance to resolve any obstacles.', img: visualiseCheckin },
    { title: 'Communicate via comments', description: "Saw some potential issues? Collaborate directly in our Daily Check-in page to resolve any matter quickly.", img: commentCheckin},
  ]
};

export const featureScrumPoker: FeatureDefinition = {
  id: 'scrum-poker',
  prefix: "Better sprint planning",
  title: "TeamKit Scrum Poker",
  shortDescription: "Seamlessly estimate the complexity of tasks, streamline team collaboration, and enhance decision-making with this interactive and efficient tool.",
  longDescription:
    "Seamlessly estimate the complexity of tasks, streamline team collaboration, and enhance decision-making with this interactive and efficient tool. Take your sprint planning to the next level and empower your team to make more accurate, informed decisions.",
  image: landingPageScrumPoker,
  features: [
    {
      name: "Real time voting.",
      description:
        "Instantly gather your team's input and reach consensus on task complexity, fostering quicker and more accurate sprint planning.",
      icon: CheckCircleIcon,
    },
    {
      name: "Customizable voting unit.",
      description:
        "Story Points, Man Hours, our customised estimation metrics, we have you covered",
      icon: CalculatorIcon,
    }
  ],
  howtos: [
    { title: 'Voting session with different scoring strategies', description: "Story Points, Man Hours, our customised estimation metrics, we have you covered", img: createSessionImg },
    { title: 'Independant voting', description: "Real time voting with TeamKit dashboard, ensure one's opinion cannot affect another during voting time", img: voteImg },
    { title: 'Online status tracking', description: "Stay connected and informed, ensuring seamless collaboration and timely communication, all at your fingertips.", img: onlineStatusImg },
    { title: 'Visualise voting stats', description: "Uncover voting results and access detailed statistics with ease through our intuitive feature. Gain valuable insights and make informed decisions based on the collective opinion of your team or audience.", img: resultsImg },
  ]
};

export const features: Array<FeatureDefinition> = [
  featureRetrospective,
  featureGoalTracking,
  featureDailyCheckin,
];
