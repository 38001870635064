import React, { useState } from "react";
import { Card, Row, Col } from 'antd';
import {
  QuestionCircleOutlined
} from '@ant-design/icons';
import VotingSessionForm from './VotingSessionForm';

interface VotingSessionCreateProps {
  onCreateCompleted: (newVotingSessionID:string) => void;
}
const VotingSessionCreate: React.FC<VotingSessionCreateProps> = ({ onCreateCompleted }) => {
  const [showForm, setShowForm] = useState<boolean>(false);

  return (
    <Row gutter={[16, 16]}>
      {!showForm &&
        <Col className="teamkit-create-card-container" xl={8} md={12} xs={24}>
          <Card className="teamkit-small-listing-card teamkit-create-card" onClick={() => { setShowForm(true) }}>
            Create voting session
          </Card>
          <a  className="teamkit-create-card-help" href="https://goteamkit.com/features/scrum_poker" target="_blank" ><QuestionCircleOutlined /></a>
        </Col>}
      {showForm &&
        <Col xxl={12} xs={24}>
          <VotingSessionForm
            onCreateCancelled={() => { setShowForm(false) }}
            onCreateCompleted={(newVotingSessionID:string) => {
              onCreateCompleted(newVotingSessionID);
              setShowForm(false);
            }}
          />
        </Col>}
    </Row>
  );
}

export default VotingSessionCreate;
