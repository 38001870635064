import React, { useState, useContext } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { AppContext } from '../../store';
import { UserAvatar } from '../modules/common';
import iconImg from '../../resources/images/logo192.png';
import blogCheckingImg from "../../resources/images/blog/checkin_1.png";
import blogRetro1Img from "../../resources/images/blog/retrospective_1.png";
import blogRetro2Img from "../../resources/images/blog/retrospective_2.png";
import { djangoPageRoutes } from '../const';
import { PlanListTailwinds } from '../modules/common';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { FeatureDefinition, features } from './features';


const navigation = [
  { name: 'Retrospectives', href: `${window.location.origin}/features/retrospective` },
  { name: 'Goals Tracking', href: `${window.location.origin}/features/goal_tracking` },
  { name: 'Daily Check-in', href: `${window.location.origin}/features/daily_checkin` },
  { name: 'Scrum Poker', href: `${window.location.origin}/features/scrum_poker` },
  { name: 'Pricing', href: `${window.location.origin}/#pricing` },
];

const resources = [
  { name: 'Retrospectives', href: `${window.location.origin}/features/retrospective` },
  { name: 'Goals Tracking', href: `${window.location.origin}/features/goal_tracking` },
  { name: 'Scrum Poker', href: `${window.location.origin}/features/scrum_poker` },
  { name: 'Daily Check-in', href: `${window.location.origin}/features/daily_checkin` },
];

const followUsLinks = [
  { name: 'LinkedIn', href: 'https://www.linkedin.com/company/teamkitapp/' },
  { name: 'About', href: `${window.location.origin}/about` },
  { name: 'Contact us', href: `${window.location.origin}/contact` },
];

const legalLinks = [
  { name: 'Terms of Use', href: `${window.location.origin}/terms` },
  { name: 'Privacy policy', href: `${window.location.origin}/privacy` },
  { name: 'Cookie policy', href: `${window.location.origin}/cookies` },
];

interface FeatureProps {
  featureDefinition: FeatureDefinition;
}

const Feature: React.FC<FeatureProps> = ({ featureDefinition }) => {
  return (
    <div id={featureDefinition.id} className="overflow-hidden py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4">
            <div className="lg:max-w-lg">
              <h2 className="text-base font-semibold leading-7 text-indigo-600">
                {featureDefinition.prefix}
              </h2>
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                {featureDefinition.title}
              </p>
              {featureDefinition.shortDescription && <p className="mt-6 text-lg leading-8 text-gray-600">
                {featureDefinition.shortDescription}
              </p>}
              <dl className="mt-10 max-w-xl space-y-8 text-base leading-7 text-gray-600 lg:max-w-none">
                {featureDefinition.features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-semibold text-gray-900">
                      {feature.icon && <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-indigo-600"
                        aria-hidden="true"
                      />}
                      {feature.name && feature.name}
                    </dt>{" "}
                    <dd className="inline">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={featureDefinition.image}
            alt="Product screenshot"
            className="w-[48rem] max-w-xl sm:max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:-ml-0"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}

export const PricingSection: React.FC<{}> = () => {
  return (
    <div id="pricing" className="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6">
      <div className="mx-auto max-w-screen-md text-center mb-8 lg:mb-12">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
          Designed for teams like yours
        </h2>
        <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
        At Teamkit, our primary focus revolves around leveraging technology and innovation to unlock the full potential of your team.
        </p>
      </div>
      <PlanListTailwinds />
    </div>
  )
}

const posts = [
  {
    id: 1,
    title: "Your Daily Stand-Up Meeting Should Not Feel Like This",
    href: 'https://www.linkedin.com/pulse/your-daily-stand-up-meeting-should-feel-like-teamkitapp/',
    description: `🤔 Does your team feel that the stand-up meeting is a way of micromanagement? Do they consider it a boring recurring activity where they simply report what they did? If any of these sentiments resonate with your team, it's time to reflect on whether you might be doing something wrong.`,
    img: blogCheckingImg,
    date: 'July 19, 2023',
    datetime: '2023-07-19',
  },
  {
    id: 2,
    title: "Retrospectives you might be doing wrong",
    description: `It's Friday, and as we wrap up another week of hard work, it's time for a Retrospective session. 🚀 But before you dive into reflecting on your long weeks, are you falling into any of the following pitfalls? #scrum #retrospectives #retrospective #softwareengineering #softwareengineer #agile`,
    img: blogRetro1Img,
    href: 'https://www.linkedin.com/pulse/retrospectives-you-might-doing-wrong-teamkitapp/',
    date: 'June 30, 2023',
    datetime: '2023-06-30',
  },
  {
    id: 3,
    title: "Unleashing the Power of Retrospectives: Key Discussion Areas for a Meaningful Reflection",
    description: `Have you ever experienced a retrospective session where your team sat in silence, unsure of what to discuss? 🤔 If so, we've got your back. Check this out to explore what you can prompt the team members to reflect on during the next Retrospectives session. 📚✨ #retrospectives #scrum #agile #team #softwareengineering #developer`,
    img: blogRetro2Img,
    href: 'https://www.linkedin.com/pulse/unleashing-power-retrospectives-key-discussion-areas-meaningful/',
    date: 'July 4, 2023',
    datetime: '2023-07-04',
  },
  {
    id: 4,
    title: "Maximizing Growth and Collaboration: Some Tips on Running Effective Retrospective Sessions",
    description: `📚 Discover key tips and strategies for running an effective Retrospectives session to unlock your team's potential and foster continuous improvement. Check out the article now for actionable insights and take your retrospectives to the next level. #retrospectives #scrum #softwareengineer #team      `,
    img: blogRetro1Img,
    href: 'https://www.linkedin.com/pulse/maximizing-growth-collaboration-some-tips-running-effective/',
    date: 'June 26, 2023',
    datetime: '2023-06-26',
  },
];

const BlogSection: React.FC<{}> = () => {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
          From the blog
        </h2>
      </div>
      <div className="mx-auto mt-6 grid max-w-lg grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 pt-10 sm:mt-8 sm:pt-16 lg:mx-0 md:max-w-none md:grid-cols-2 lg:grid-cols-3">
        {posts.map((post) => (
          <article
            key={post.id}
            className="flex max-w-xl flex-col items-start justify-between"
          >
            <div className="flex items-center gap-x-4 text-xs">
              <time dateTime={post.datetime} className="text-gray-500">
                {post.date}
              </time>
            </div>
            <div className="group relative">
              <h3 className="mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                <a href={post.href}>
                  <span className="absolute inset-0" />
                  {post.title}
                </a>
              </h3>
              <img
                src={post.img}
                alt="Blog cover"
                className="rounded-xl shadow-xl"
                width={2432}
                height={1442}
              />
              <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                {post.description}
              </p>
            </div>
          </article>
        ))}
      </div>
    </div>
  );
}

export const Footer: React.FC<{}> = () => {
  return (
    <footer className="bg-gray-900 mt-24 sm:mt-48 gap-x-8 gap-y-16 pt-4 sm:pt-8">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center">
              <img
                src={iconImg}
                className="h-8 mr-3"
                alt="TeamKit Logo"
              />
              <span className="self-center text-2xl font-semibold whitespace-nowrap text-white">
                Teamkit
              </span>
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">
                Resources
              </h2>
              <ul className="text-gray-500 text-gray-400 font-medium">
                {resources.map((item) => (
                  <li key={item.name} className="mb-4">
                    <a
                      href={item.href}
                      className="hover:underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">
                Follow us
              </h2>
              <ul className="text-gray-500 text-gray-400 font-medium">
                {followUsLinks.map((item) => (
                  <li key={item.name} className="mb-4">
                    <a
                      href={item.href}
                      className="hover:underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase text-white">
                Legal
              </h2>
              <ul className="text-gray-500 text-gray-400 font-medium">
                {legalLinks.map((item) => (
                  <li key={item.name} className="mb-4">
                    <a
                      href={item.href}
                      className="hover:underline"
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm sm:text-center text-gray-400">
            © 2023{" "}
            <a href="/" className="hover:underline">
              TeamKit
            </a>
            . All Rights Reserved.
          </span>
        </div>
      </div>
    </footer>
  );
}

const Divider = () => {
  return (
    <div className="mx-auto max-w-7xl py-24 sm:px-6 sm:py-32 lg:px-8" />
  );
}

export const Header: React.FC<{}> = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { state: { currentUser } } = useContext(AppContext);
  
  return(
    <header className="absolute inset-x-0 top-0 z-50">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/" className="-m-1.5 p-1.5">
            <span className="sr-only">TeamKit</span>
            <img
              className="h-8 w-auto"
              src={iconImg}
              alt=""
            />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="text-sm font-semibold leading-6 text-gray-900"
            >
              {item.name}
            </a>
          ))}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a
            href={`${window.location.origin}${djangoPageRoutes.login}`}
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            { currentUser ? <><UserAvatar username={currentUser.username} />&nbsp;Go to app </> : <span>Log in </span>}
            <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">TeamKit</span>
              <img
                className="h-8 w-auto"
                src={iconImg}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
              <div className="py-6">
                <a
                  href={`${window.location.origin}${djangoPageRoutes.login}`}
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >
                  { currentUser ? <><UserAvatar username={currentUser.username} />&nbsp;Go to app</> : <span>Log in</span>}
                </a>
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}

const LandingPageTailwinds: React.FC<RouteComponentProps> = ({ history }) => {
  return (
    <div className="bg-white font-sans">
      <Header />
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div
          className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        <div className="mx-auto max-w-2xl py-32 sm:py-48 lg:py-56">
          <div className="text-center">
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
              All-in-one tool <br />
              for any Scrum team
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Retrospective, goal tracking, daily check-in, scrum poker, task
              management. Everything your team need in just one place.
            </p>
            <div className="mt-10 flex items-center justify-center gap-x-6">
              <a
                href={`${window.location.origin}${djangoPageRoutes.signup}`}
                className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Sign Up
              </a>
            </div>
          </div>
        </div>
        <div
          className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
          aria-hidden="true"
        >
          <div
            className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
            }}
          />
        </div>
        {features.map((feature) => (
          <Feature featureDefinition={feature} />
        ))}
      </div>
      <PricingSection />
      <Divider />
      <BlogSection />
      <Footer />
    </div>
  );
}

export default withRouter(LandingPageTailwinds);
