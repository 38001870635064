import _ from 'lodash-es';
import React, { Fragment, useContext } from "react";
import { Row, Col, Spin, Card, Space, Button } from 'antd';
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getBasePathWithTeam } from '../../helpers';
import { Team, User } from '../../models';
import { AppContext } from '../../../store';

interface TeamCardProps {
  currentUser: User;
  team: Team;
  history: any;
}

const TeamCard: React.FC<TeamCardProps> = ({ team, currentUser, history }) => {
  const openTeamPage = () => {
    history.push(`${getBasePathWithTeam()}/teams/${team.id}`)
  }
  const onCardClick = (e:any) => {
    // Special handler for card body click, to handle clicks that are in the card body padding
    if(e.target.className === 'ant-card-body') {
      openTeamPage()
    }
  }

  return (
    <Card className="teamkit-small-listing-card" onClick={onCardClick}>
      <div onClick={openTeamPage}>
        <Space direction="vertical" style={{ width: '100%' }}>
          <Row >
            <b>{team.name}</b>
          </Row>
        </Space>
      </div>
    </Card>
  );
}

const TeamList: React.FC<RouteComponentProps<{}>> = ({ history }) => {
  const { state: { currentUser } } = useContext(AppContext);
  
  if (!currentUser) {
    return <Spin />;
  }

  const asAdmin: Array<Team> = [];
  const asMember: Array<Team> = [];
  _.each(currentUser.team_membership, (team:Team) => {
    if (_.filter(team.teamadmin_set, (admin) => admin.username === currentUser.username).length > 0) { 
      asAdmin.push(team);
    } else {
      asMember.push(team);
    }
  })

  return (
    <Fragment>
      <Row gutter={[16, 16]}>
        <Col xl={8} md={12} xs={24}>
          <Card className="teamkit-small-listing-card create-board-card" onClick={() => { history.push(`${getBasePathWithTeam()}/teams/create`) }}>
            Create new team
          </Card>
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="teamkit-section-title">
          ADMIN OF
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
      {asAdmin.length > 0 && asAdmin.map((team:Team) => (
        <Col key={team.id} xl={8} md={12} xs={24}>
          <TeamCard
            currentUser={currentUser}
            team={team}
            history={history}
          />
        </Col>
      ))}
      {asAdmin.length === 0 &&
        <Col xs={24}>
          No team yet. <Button onClick={() => { history.push(`${getBasePathWithTeam()}/teams/create`)}}>Create</Button> a team now to start using TeamKit features
        </Col>
      }
      </Row>
      <Row gutter={[16, 16]}>
        <Col xs={24} className="teamkit-section-title">
          MEMBER OF
        </Col>
      </Row>
      <Row gutter={[16, 16]}>
      {asMember.length > 0 && asMember.map((team:Team) => (
        <Col key={team.id} xl={8} md={12} xs={24}>
          <TeamCard
            currentUser={currentUser}
            team={team}
            history={history}
          />
        </Col>
      ))}
      {asMember.length === 0 &&
        <Col xs={24}>No team yet. Ask your team admin to add you to a team</Col>
      }
      </Row>
    </Fragment>
  );
}

export default withRouter(TeamList);
